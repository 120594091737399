import axios from "axios";

/**
 *  Singleton API wrapper for axios using async/await and support for cancel tokens
 *  Note: Singleton is implemented as an IIF expression, but could also be done the ES6 way
 *
 *   const API = {
 *     get: async (url) => {
 *       return await axios.get(url, { withCredentials: true });
 *     },
 *     ...
 *   }
 ***********************
 *   may need to add
 *
 *     Pragma: "no-cache"
 *
 *   to header for IE11
 ***********************
 *   Object.freeze(API);
 *   export{ API };
 *
 */
const API = (function () {
  return {
    instance: axios,
    get: async (url, baseURL, token) => {
      return await axios({
        url,
        ...(token && { headers: { Authorization: `bearer ${token}` } }),
        method: "get",
        baseURL,
        withCredentials: true,
      });
    },

    post: async (url, baseURL, data) => {
      return await axios({ url, method: "post", data, baseURL, withCredentials: true });
    },

    put: async (url, baseURL, data) => {
      return await axios({ url, method: "put", data, baseURL, withCredentials: true });
    },

    delete: async (url, baseURL) => {
      return await axios({ url, method: "delete", baseURL, withCredentials: true });
    },

    // create a token to be able to cancel a request
    makeCancelToken: () => {
      return axios.CancelToken.source();
    },
  };
})();

export { API };
