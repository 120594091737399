/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_YbOhJKgs6",
  aws_user_pools_web_client_id: "47pegrbg0d40n893tmafterur6",
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://c62tm0d0x2.execute-api.us-east-1.amazonaws.com/dev",
      region: "us-east-1",
    },
  ],
};

export default awsmobile;
