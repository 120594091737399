import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "../middleware/logger";
import { debugLogTime } from "../utils/common";

// logging interceptor
const logger = ({ getState }) => {
  return (next) => (action) => {
    const { type, ...payload } = action;
    debugLogTime("ACTION:", { type, payload }, "Store");

    return next(action);
  };
};

const configureStore = (preloadedState) => {
  const middlewares = [thunkMiddleware, logger];

  if (process.env.NODE_ENV === "development") {
    middlewares.push(loggerMiddleware);
  }
  const composeEnhancers =
    (process.env.REACT_APP_STAGE !== "production" &&
      typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  );
  return createStore(rootReducer, preloadedState, enhancer);
};

const store = configureStore();
export default store;
