import { ADD_COGNITO_AUTH } from "../actions/authActions";

export default function cognito(state = {}, action) {
  switch (action.type) {
    case ADD_COGNITO_AUTH:
      const newState = { ...state, ...action.authData };
      return newState;
    default:
      return state;
  }
}
