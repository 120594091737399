import merge from "deepmerge";
import uuid from "uuid4";
import Config from "../../config";
import { API } from "../../api/api";

import { ADD_PROFILE, UPDATE_PROFILE, DELETE_PROFILE, UPDATE_PROFILE_LIST } from "../../actions/profileActions";

import {
  UPDATE_USER_DATA,
  UPDATE_NPS,
  ADD_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  CLONE_REPORTS_BY_ID,
  ADD_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,
  UPDATE_HOME_PAGE_DATA,
  UPDATE_REPORT_LIST,
  UPDATE_DASHBOARD_LIST,
  COPY_REPORT,
  COPY_DASHBOARD,
  UPDATE_COUNTERS,
  UPDATE_TOKEN,
  UPDATE_SQL_AGGREGATIONS,
  UPDATE_SQL_FUNCTIONS,
  UPDATE_PERFORMANCE_OBJECTIVES,
} from "../../actions/userDataActions";

const updateReportsAndDashboards = (state, action, reports, dashboards, profiles, name) => {
  const user = {
    reports: Object.values(reports)
      .filter((node) => !(node.isDefault || node.isCommon))
      .reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
      }, {}),
    dashboards: Object.values(dashboards)
      .filter((node) => !(node.isDefault || node.isCommon))
      .reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
      }, {}),
    profiles: Object.values(profiles)
      .filter((node) => !(node.isDefault || node.isCommon))
      .reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
      }, {}),
  };

  // reconstruct user data
  const updated = { ...state };
  updated.reports = user.reports;
  updated.dashboards = user.dashboards;
  updated.profiles = user.profiles;

  API.post(
    `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
    Config.baseURLUserService,
    updated
  );

  const result = { ...state, reports, dashboards, profiles };
  return result;
};

export const handleRegularUserData = (state, action) => {
  switch (action.type) {
    case UPDATE_PERFORMANCE_OBJECTIVES: {
      const updated = { ...state, preferences: state.preferences };
      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );

      // update for redux only
      return state;
    }
    /**
     * Called when report list has changed due to reordering or moving of reports
     */
    case UPDATE_REPORT_LIST: {
      // admin users have common, default and user reports
      // but they need to be stored separately as common and default will be user by others, only user reports are stored in userData S3 bucket
      // user -> userData-<userId>.json
      // default -> defaultCommon.json
      // common -> commonData.json
      const user = {
        reports: Object.values(action.reports)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        dashboards: Object.values(state.dashboards)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        profiles: Object.values(state.profiles)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
      };

      // reconstruct user data
      const updated = { ...state };
      updated.reports = user.reports;
      updated.dashboards = user.dashboards;
      updated.profiles = user.profiles;

      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );

      // update for redux only
      return { ...state, reports: action.reports };
    }

    /**
     * Called when dashboard list has changed due to reordering or moving of dashboards
     */
    case UPDATE_DASHBOARD_LIST: {
      // admin users have common, default and user reports
      // but they need to be stored separately as common and default will be user by others, only user reports are stored in userData S3 bucket
      // user -> userData-<userId>.json
      // default -> defaultCommon.json
      // common -> commonData.json

      const user = {
        reports: Object.values(state.reports)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        dashboards: Object.values(action.dashboards)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        profiles: Object.values(state.profiles)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
      };

      // reconstruct user data
      const updated = { ...state };
      updated.reports = user.reports;
      updated.dashboards = user.dashboards;
      updated.profiles = user.profiles;

      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );

      // update for redux only
      return { ...state, dashboards: action.dashboards };
    }

    /**
     * TODO: This needs some work...
     *
     *     only saving date of last NPS but not the NPS itself...
     */
    case UPDATE_NPS: {
      const updated = { ...state, lastNPS: action.date };

      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );
      return updated;
    }

    /**
     * Updating user data when license, preferences, etc changes.
     */
    case UPDATE_USER_DATA: {
      // Need to remove any reports and dashboards which are common
      const user = {
        reports: Object.values(action.userData.reports)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        dashboards: Object.values(action.userData.dashboards)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        profiles: Object.values(action.userData.profiles)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
      };
      const userId = state.userId || action.userData.userId;
      const updated = { ...action.userData };
      updated.reports = user.reports;
      updated.dashboards = user.dashboards;
      updated.profiles = user.profiles;

      API.post(
        `/writeUserData/${userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );
      return action.userData;
    }

    /**
     * Updates Home page data
     */
    case UPDATE_HOME_PAGE_DATA: {
      const updated = { ...state, home: action.home, modifiedHome: true };

      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );

      return updated;
    }
    case ADD_REPORT:
    case UPDATE_REPORT: {
      // update or add report if a common report is being edited.
      const reports = { ...state.reports, ...{ [action.report.id]: action.report } };

      // check if currently selected report is the updated one, in most cases it is and hence
      // the selected report has to been updated as well or some views will still show the old selected report
      if (action?.selection?.report && action.selection.report.id === action.report.id) {
        action.selection.report = action.report;
      }
      return updateReportsAndDashboards(state, action, reports, state.dashboards, state.profiles, "report");
    }

    case DELETE_REPORT: {
      const reports = { ...state.reports };

      // remove the report from the map of reports
      delete reports[action.report.id];
      return updateReportsAndDashboards(state, action, reports, state.dashboards, state.profiles, "report");
    }

    case COPY_REPORT: {
      // make a copy or really weird things will happen if you modifiy the original
      const clonedReport = merge({}, action.report);
      clonedReport.id = uuid();
      delete clonedReport.isCommon;
      clonedReport.name = "Copy of " + action.report.name;

      const reports = { ...state.reports, ...{ [clonedReport.id]: clonedReport } };
      return updateReportsAndDashboards(state, action, reports, state.dashboards, state.profiles, "report");
    }

    case CLONE_REPORTS_BY_ID: {
      let allUserReports = Object.values(state.reports)
        .filter((node) => !(node.isDefault || node.isCommon))
        .reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {});

      const reportIds = action.reportIds;
      reportIds.forEach((id) => {
        const clone = merge({}, action.reports[id]);
        clone.id = uuid();
        clone.isCommon = false;
        allUserReports = merge(allUserReports, { [clone.id]: clone });
      });

      const allReports = { ...allUserReports, ...state.reports };

      const updated = { ...state };
      updated.reports = allReports;

      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );
      return updated;
    }

    case COPY_DASHBOARD: {
      // make a copy or really weird things will happen if you modifiy the original
      const clonedDashboard = merge({}, action.dashboard);
      clonedDashboard.id = uuid();
      delete clonedDashboard.isCommon;
      clonedDashboard.name = "Copy of " + action.dashboard.name;

      const dashboards = { ...state.dashboards, ...{ [clonedDashboard.id]: clonedDashboard } };
      return updateReportsAndDashboards(state, action, state.reports, dashboards, state.profiles, "dashboard");
    }

    case UPDATE_DASHBOARD:
    case ADD_DASHBOARD: {
      const dashboards = { ...state.dashboards, ...{ [action.dashboard.id]: action.dashboard } };
      return updateReportsAndDashboards(state, action, state.reports, dashboards, state.profiles, "dashboard");
    }

    case DELETE_DASHBOARD: {
      // remove the dashboard from the map of dashboards
      const dashboards = { ...state.dashboards };

      // remove the report from the map of reports
      delete dashboards[action.dashboard.id];
      return updateReportsAndDashboards(state, action, state.reports, dashboards, state.profiles, "dashboard");
    }

    case UPDATE_PROFILE_LIST: {
      // admin users have common, default and user profiles
      // but they need to be stored separately as common and default will be user by others, only user profiles are stored in userData S3 bucket
      // user -> userData-<userId>.json
      // default -> defaultCommon.json
      // common -> commonData.json

      const user = {
        reports: Object.values(state.reports)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        dashboards: Object.values(state.dashboards)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
        profiles: Object.values(action.profiles)
          .filter((node) => !(node.isDefault || node.isCommon))
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {}),
      };

      // reconstruct user data
      const updated = { ...state };
      updated.profiles = user.profiles;

      API.post(
        `/writeUserData/${state.userId}?bucketName=${Config.dataBucket}&filenamePrefix=userData`,
        Config.baseURLUserService,
        updated
      );

      // update for redux only
      return { ...state, profiles: action.profiles };
    }

    case ADD_PROFILE:
    case UPDATE_PROFILE: {
      // update profile if a common report is being edited.
      const profiles = { ...state.profiles, ...{ [action.profile.id]: action.profile } };

      return updateReportsAndDashboards(state, action, state.reports, state.dashboards, profiles, "profile");
    }

    case DELETE_PROFILE: {
      // remove the profile from the map of profiles
      const profiles = { ...state.profiles };

      // remove the profile from the map of profiles
      delete profiles[action.profile.id];
      return updateReportsAndDashboards(state, action, state.reports, state.dashboards, profiles, "profile");
    }

    case UPDATE_COUNTERS: {
      const newState = { ...state };
      newState.counters = action.counters;
      return updateReportsAndDashboards(newState, action, state.reports, state.dashboards, state.profiles, "profile");
    }

    case UPDATE_TOKEN: {
      const newState = { ...state };
      newState.auth.access_token = action.token.token;
      newState.auth.expires = action.token.expires;
      return updateReportsAndDashboards(newState, action, state.reports, state.dashboards, state.profiles, "profile");
    }

    case UPDATE_SQL_FUNCTIONS: {
      const newState = { ...state };
      newState.functions = action.sqlFunctions;
      return updateReportsAndDashboards(newState, action, state.reports, state.dashboards, state.profiles, "profile");
    }

    case UPDATE_SQL_AGGREGATIONS: {
      const newState = { ...state };
      newState.aggregations = action.sqlAggregations;
      return updateReportsAndDashboards(newState, action, state.reports, state.dashboards, state.profiles, "profile");
    }

    default:
      return state;
  }
};
