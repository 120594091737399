import React, { createContext, useReducer, useContext } from "react";

const ApplicationContext = createContext();

function applicationReducer(state, action) {
  switch (action.type) {
    case "init":
      console.log("init");
      break;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ApplicationProvider({ children, init = {} }) {
  const [state, dispatch] = useReducer(applicationReducer, init);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
}

function useAppData() {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a ApplicationProvider");
  }
  return context;
}

export { ApplicationProvider, useAppData };
