import * as colors from "@material-ui/core/colors";
import OpenSansRegularWoff from "../assets/fonts/OpenSans-Regular-webfont.woff";
import OpenSansRegularTtf from "../assets/fonts/OpenSans-Regular-webfont.ttf";
import OpenSansItalicWoff from "../assets/fonts/OpenSans-Italic-webfont.woff";
import OpenSansItalicTtf from "../assets/fonts/OpenSans-Italic-webfont.ttf";

const opensans = {
  fontFamily: "OpenSans",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `
  url(${OpenSansRegularWoff}) format('woff')
  url(${OpenSansRegularTtf}) format('truetype')
  `,
};

const opensansitalic = {
  fontFamily: "OpenSansItalic",
  fontStyle: "italic",
  fontWeight: "normal",
  src: `
  url(${OpenSansItalicWoff}) format('woff')
  url(${OpenSansItalicTtf}) format('truetype')
  `,
};

const colorTheme = {
  primary: "#eef0ff",
  primaryDark: "#31465F",
};

const theme = {
  reportZone: {
    colors: {
      primary: colorTheme.primary,
      primaryDask: colorTheme.primaryDask,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["OpenSans,OpenSansItalic"].join(","),
  },
  status: {
    danger: colors.orange,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [opensans, opensansitalic],
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colorTheme.primary,
        color: "black",
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: colorTheme.primary,
        color: colorTheme.primaryDark,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "unset",
      },
    },
  },

  palette: {
    type: "light",
    primary: {
      main: "#bfcae6",
    },
    secondary: {
      main: "#f50057",
    },
  },
};

export default theme;
