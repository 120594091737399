export const UPDATE_API_CLIO = "apiApp.UPDATE_API_CLIO";
export const UPDATE_API_PANTHER = "apiApp.UPDATE_API_PANTHER";

export function setApiAuthClio(clio) {
  return {
    type: UPDATE_API_CLIO,
    clio,
  };
}
export function setApiAuthPanther(panther) {
  return {
    type: UPDATE_API_PANTHER,
    panther,
  };
}
