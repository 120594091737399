import { debugLogTime } from "../utils/common";

export const UPDATE_USER_DATA = "userData.UPDATE";
export const UPDATE_NPS = "userData.UPDATE_NPS";
export const ADD_REPORT = "userData.ADD_REPORT";
export const DELETE_REPORT = "userData.DELETE_REPORT";
export const UPDATE_REPORT = "userData.UPDATE_REPORT";
export const CLONE_REPORTS_BY_ID = "userData.CLONE_REPORTS_BY_ID";
export const ADD_DASHBOARD = "userData.ADD_DASHBOARD";
export const DELETE_DASHBOARD = "userData.DELETE_DASHBOARD";
export const UPDATE_DASHBOARD = "userData.UPDATE_DASHBOARD";

export const UPDATE_HOME_PAGE_DATA = "userData.UPDATE_HOME_PAGE_DATA";

export const UPDATE_REPORT_LIST = "userData.UPDATE_REPORT_LIST";
export const UPDATE_DASHBOARD_LIST = "userData.UPDATE_DASHBOARD_LIST";

export const COPY_REPORT = "userData.COPY_REPORT";
export const COPY_DASHBOARD = "userData.COPY_DASHBOARD";

export const UPDATE_COUNTERS = "userData.UPDATE_COUNTERS";
export const UPDATE_TOKEN = "userData.UPDATE_TOKEN";
export const UPDATE_SQL_FUNCTIONS = "userData.UPDATE_SQL_FUNCTIONS";
export const UPDATE_SQL_AGGREGATIONS = "userData.UPDATE_SQL_AGGREGATIONS";
export const UPDATE_PERFORMANCE_OBJECTIVES = "userData.UPDATE_PERFORMANCE_OBJECTIVES";

export const updatePerformanceObjectives = (performance_objectives) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_PERFORMANCE_OBJECTIVES,
    performance_objectives,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
    isStandalone: getState().userData.auth && !!getState().userData.auth.standalone,
    // ATTENTION: anti-pattern for getting top level state
    // useful when trying to access state data in other reducers
    currentState: getState(),
  });
};

export const updateDashboardlist = (dashboards) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_DASHBOARD_LIST,
    dashboards,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
    isStandalone: getState().userData.auth && !!getState().userData.auth.standalone,
    // ATTENTION: anti-pattern for getting top level state
    // useful when trying to access state data in other reducers
    currentState: getState(),
  });
};

export const updateReportlist = (reports) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_REPORT_LIST,
    reports,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
    isStandalone: getState().userData.auth && !!getState().userData.auth.standalone,
    // ATTENTION: anti-pattern for getting top level state
    // useful when trying to access state data in other reducers
    currentState: getState(),
  });
};

export const updateCounters = (counters) => {
  debugLogTime("ACTION: updateCounters", counters, "Actions");
  return {
    type: UPDATE_COUNTERS,
    counters,
  };
};

export const updateToken = (token) => {
  debugLogTime("ACTION: updateToken", token, "Actions");
  return {
    type: UPDATE_TOKEN,
    token,
  };
};

export const updateUserData = (userData, isAdmin, isFirmAdmin) => (dispatch, getState) => {
  debugLogTime("ACTION: updateUserData", userData, "Actions");
  dispatch({
    type: UPDATE_USER_DATA,
    userData,
    isAdmin,
    isFirmAdmin,
    isStandalone: getState().userData.auth && !!getState().userData.auth.standalone,
    // ATTENTION: anti-pattern for getting top level state
    // useful when trying to access state data in other reducers
    currentState: getState(),
  });
};

export const updateNPS = (date) => {
  debugLogTime("ACTION: updateNPS", date, "Actions");
  return {
    type: UPDATE_NPS,
    date,
  };
};

export const updateHomePage = (home, isAdmin, isFirmAdmin) => (dispatch, getState) => {
  debugLogTime("ACTION: updateHomePage", home, "Actions");
  dispatch({
    type: UPDATE_HOME_PAGE_DATA,
    home,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
    isStandalone: getState().userData.auth && !!getState().userData.auth.standalone,

    // ATTENTION: anti-pattern for getting top level state
    // useful when trying to access state data in other reducers
    currentState: getState(),
  });
};

export const addReport = (report) => (dispatch, getState) => {
  debugLogTime("ACTION: addReport", report, "Actions");
  dispatch({
    type: ADD_REPORT,
    report,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
  });
};

export const cloneReportsById = (reportIds, reports) => (dispatch, getState) => {
  debugLogTime("ACTION: cloneReportsById", reportIds, "Actions");
  dispatch({
    type: CLONE_REPORTS_BY_ID,
    reportIds,
    reports,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
  });
};

export function deleteReport(report, isAdmin, isFirmAdmin) {
  debugLogTime("ACTION: deleteReport", report, "Actions");
  return {
    type: DELETE_REPORT,
    report,
    isAdmin,
    isFirmAdmin,
  };
}

export function copyReport(report, isAdmin, isFirmAdmin) {
  debugLogTime("ACTION: copyReport", report, "Actions");
  return {
    type: COPY_REPORT,
    report,
    isAdmin,
    isFirmAdmin,
  };
}

export const updateReport = (report) => (dispatch, getState) => {
  debugLogTime("ACTION: updateReport", report, "Actions");
  dispatch({
    type: UPDATE_REPORT,
    report,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
    selection: getState().selection,
  });
};

export const addDashboard = (dashboard) => (dispatch, getState) => {
  debugLogTime("ACTION: addDashboard", dashboard, "Actions");
  dispatch({
    type: ADD_DASHBOARD,
    dashboard,
    isAdmin: getState().cognito.groups.includes("admin"),
    isFirmAdmin: getState().cognito.groups.includes("firm-admin"),
  });
};

export function copyDashboard(dashboard, isAdmin, isFirmAdmin) {
  debugLogTime("ACTION: copyDashboard", dashboard, "Actions");
  return {
    type: COPY_DASHBOARD,
    dashboard,
    isAdmin,
    isFirmAdmin,
  };
}

export function updateDashboard(dashboard, isAdmin, isFirmAdmin) {
  debugLogTime("ACTION: updateDashboard", dashboard, "Actions");
  return {
    type: UPDATE_DASHBOARD,
    dashboard,
    isAdmin,
    isFirmAdmin,
  };
}

export function deleteDashboard(dashboard, isAdmin, isFirmAdmin) {
  debugLogTime("ACTION: deleteDashboard", dashboard, "Actions");
  return {
    type: DELETE_DASHBOARD,
    dashboard,
    isAdmin,
    isFirmAdmin,
  };
}

export function updateSqlFunctions(sqlFunctions) {
  debugLogTime("ACTION: updateSqlFunctions", sqlFunctions, "Actions");
  return {
    type: UPDATE_SQL_FUNCTIONS,
    sqlFunctions,
  };
}

export function updateSqlAggregations(sqlAggregations) {
  debugLogTime("ACTION: updateSqlAggregations", sqlAggregations, "Actions");
  return {
    type: UPDATE_SQL_AGGREGATIONS,
    sqlAggregations,
  };
}
