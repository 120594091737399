export const SET_SELECTED_REPORT = "selected.REPORT";
export const SET_SELECTED_DASHBOARD = "selected.DASHBOARD";

export function setSelectedReport(report) {
  return {
    type: SET_SELECTED_REPORT,
    report,
  };
}

export function setSelectedDashboard(dashboard) {
  return {
    type: SET_SELECTED_DASHBOARD,
    dashboard,
  };
}
