import React from "react";
import config from "./aws-exports.old";

import { Authenticator, Greetings, SignIn } from "aws-amplify-react";

import CustomSignIn from "./CustomSignIn";
import App from "./App";

const AppWithAuth = (props) => {
  return (
    <Authenticator amplifyConfig={config} hide={[Greetings, SignIn]}>
      <CustomSignIn />
      <App />
    </Authenticator>
  );
};

export default AppWithAuth;
