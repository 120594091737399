import merge from "deepmerge";
import { API } from "../api/api";
import Config from "../config";

import { UPDATE_FEATURE_REQUESTS } from "../actions/featureActions";
import { UPDATE_FEATURE_LIST } from "../actions/featureActions";
import { UPDATE_FEATURE_REQUEST } from "../actions/featureActions";
import { UPDATE_FEATURE } from "../actions/featureActions";

export default function features(
  state = {
    requestsLoaded: false,
    requests: [],
    featurelistLoaded: false,
    featurelist: [],
  },
  action
) {
  switch (action.type) {
    case UPDATE_FEATURE_REQUESTS: {
      const newState = merge(state, action.requests);
      newState.requestsLoaded = true;
      return newState;
    }
    case UPDATE_FEATURE_REQUEST: {
      state.requests[action.request.id] = action.request;
      // write to S3 bucket
      API.post(`/addFeatureWish?bucketName=report-zone-data`, Config.baseURLCommonService, action.request);

      return state;
    }
    case UPDATE_FEATURE_LIST: {
      const newState = merge(state, action.featurelist);
      newState.featurelistLoaded = true;
      return newState;
    }
    case UPDATE_FEATURE: {
      state.featurelist[action.feature.id] = action.feature;
      API.post(`/addFeatureNews?bucketName=report-zone-data`, Config.baseURLCommonService, action.feature);
      return state;
    }
    default:
      return state;
  }
}
