export const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingTop: "100px",
    height: "100%",
  },
  card: {
    display: "inherit",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    width: "560px",
  },
  logo: {
    display: "flex",
    alignContent: "center",
    alignSelf: "center",
  },
  logoImage: {
    maxHeight: "120px",
  },
  logoText: {
    marginTop: "24px",
    marginLeft: "12px",
  },
  logoFont: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  formControl: {
    alignSelf: "center",
    marginBottom: "32px",
  },
  button: {
    alignSelf: "center",
    marginBottom: "32px",
    color: "white",
    backgroundColor: "#10b9df",
    backgroundImage: "linear-gradient(-180deg,#10b9df 0%,#0c91af 100%)",
    border: "1px solid #0c91af",
  },
  ssoButton: {
    alignSelf: "center",
    marginBottom: "32px",
  },
  textfield: {
    width: "450px",
  },
  textHelper: {
    marginTop: "16px",
    marginLeft: "32px",
    fontSize: "12px",
  },
  buttonLink: {
    marginLeft: "24px",
    color: "#0077cc",
    fontSize: "12px",
    "&:hover": {
      color: "#0077ccb5",
    },
  },
});
