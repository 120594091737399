import { UPDATE_API_CLIO, UPDATE_API_PANTHER } from "../actions/apiAppActions";

export default function appApi(state = {}, action) {
  switch (action.type) {
    case UPDATE_API_CLIO: {
      const newState = { clio: action.clio };
      return { ...state, ...newState };
    }
    case UPDATE_API_PANTHER: {
      const newState = { panther: action.panther };
      return { ...state, ...newState };
    }
    default:
      return state;
  }
}
