const dev = {
  missingLanguageTemplate: "no [{{language}}] for {{value}}",
  missingTranslationTemplate: " missing -> {{value}}",
  id: "YsgViEggjsPRulezgYOoWA6tCquMuKADSOYoreP3",
  secrect: "rV28eJApvnFSGITeRcXLPybHEQprgb1G5l5ErUSM",
  "eu.id": "xU3o6fe6zeFCHGjZRSqVl3o6eOnBpHCok1TqUaw7",
  "eu.secrect": "8XryAYui9uVY6AjHAIvg3SM0qsG9cf3ybzq4B01W",
  "ca.id": "MWKIUbhU7Z3oPlMU5NYLjSpltIWIPV3R0sQwU04c",
  "ca.secrect": "RLiyZNErN71CvNhSspphBn8SJXKZRSaI0oJxwrrg",
  "au.id": "",
  "au.secrect": "",

  ssoKey: "1149587a-4510-4798-b525-0dc4fe26953d",
  ssoSecret: "-TTocpuvd_yOyEAlOhRd2fd63k",

  baseURLCommonService: "http://localhost:4500/dev",
  baseURLUserService: "http://localhost:4501/dev",
  baseURLAdminService: "http://localhost:4502/dev",
  baseURLLicenseService: "http://localhost:4503/dev",
  redirectUri: "https://api.report-zone.com/common/oauth/response",
  localRedirect: "http://localhost:3000/home/account/license",
  dataBucket: "report-zone-data",
  conflictCheckEnabled: true,
  reportingEnabled: true,
  visualizationCheckEnabled: true,
  adminEnabled: true,
  dataEnabled: true,
  accountEnabled: true,
  preferencesEnabled: true,
  bugReportEnabled: true,
  sandboxEnabled: true,
  featureRequestsEnabled: true,
  expressProxy: "http://localhost:5555/",
};

const prod = {
  missingLanguageTemplate: "{{value}}",
  missingTranslationTemplate: "{{value}}",
  id: "YsgViEggjsPRulezgYOoWA6tCquMuKADSOYoreP3",
  secrect: "rV28eJApvnFSGITeRcXLPybHEQprgb1G5l5ErUSM",
  "eu.id": "xU3o6fe6zeFCHGjZRSqVl3o6eOnBpHCok1TqUaw7",
  "eu.secrect": "8XryAYui9uVY6AjHAIvg3SM0qsG9cf3ybzq4B01W",
  "ca.id": "MWKIUbhU7Z3oPlMU5NYLjSpltIWIPV3R0sQwU04c",
  "ca.secrect": "RLiyZNErN71CvNhSspphBn8SJXKZRSaI0oJxwrrg",
  "au.id": "",
  "au.secrect": "",

  ssoKey: "1149587a-4510-4798-b525-0dc4fe26953d",
  ssoSecret: "-TTocpuvd_yOyEAlOhRd2fd63k",

  baseURLCommonService: "https://api.report-zone.com/common/",
  baseURLAdminService: "https://api.report-zone.com/admin/",
  baseURLUserService: "https://api.report-zone.com/user/",
  baseURLLicenseService: "https://api.report-zone.com/license/",
  redirectUri: "https://api.report-zone.com/common/oauth/response",
  localRedirect: "https://app.report-zone.com/home/account/license",
  dataBucket: "report-zone-data",
  conflictCheckEnabled: true,
  reportingEnabled: true,
  visualizationCheckEnabled: true,
  adminEnabled: true,
  dataEnabled: true,
  accountEnabled: true,
  preferencesEnabled: true,
  sandboxEnabled: true,
  bugReportEnabled: true,
  featureRequestsEnabled: true,
  expressProxy: "https://s3.report-zone.com/",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

const CONFIG = {
  ...config,
};

export default CONFIG;
