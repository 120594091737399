export const UPDATE_APP_STATE = "appState.UPDATE";
export const USER_LOGOUT = "USER_LOGOUT";

export function updateAppState(appState) {
  return {
    type: UPDATE_APP_STATE,
    appState,
  };
}
export function userLogout() {
  return {
    type: USER_LOGOUT,
    value: 1,
  };
}
