import { handleAdminUserData } from "./admin/helper";
import { handleFirmUserData } from "./firm/helper";
import { handleRegularUserData } from "./user/helper";

export default function userData(state = {}, action) {
  // handle different user groups
  if (action.isAdmin) {
    return handleAdminUserData(state, action);
  } else if (action.isFirmAdmin) {
    return handleFirmUserData(state, action);
  } else {
    return handleRegularUserData(state, action);
  }
}
