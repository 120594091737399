import React from "react";

import Loading from "./components/Loading";
import { imported } from "react-imported-component";
import { ApplicationProvider } from "./ApplicationContext";

// code splitting for performance
// only load what we need based on route
const MainContainer = imported(() => import("./main/MainContainer"), {
  LoadingComponent: () => <Loading message="Loading Main Page..."></Loading>,
});

const App = (props) => {
  return props.authState === "signedIn" ? (
    <ApplicationProvider init={{ auth: props.authData }}>
      <MainContainer authData={props.authData} {...props} />
    </ApplicationProvider>
  ) : null;
};

export default App;
