import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import useStyles from "./Loading.styles";

export default function Loading(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" disableShrink={true} />
      <Typography className={classes.message} variant="h6" gutterBottom>
        {props.message || null}
      </Typography>
    </div>
  );
}
