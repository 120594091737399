import { createSelector } from "reselect";
/**
 * Debug logging that can be enabled at run time and in production
 *
 * @param {string} msg the message to display
 * @param {object} object the object to log
 * @param {string} enabledModule the enabling string for a given module, this can be any free format string
 */
export const debugLogTime = (msg, object, enabledModule, trace) => {
  /**
   * for debugging enabled by localstorage setting "debugLogTime"
   * format is an array of strings
   * supported:
   *   [
   *     'ContentContainer.render',
   *     'ContentContainer.polling'
   *   ]
   * paste into localStorage to enable logging immediately
   *
   * Example
   *  i.e. "['ContentContainer.render','ContentContainer.polling']"
   *
   *  Usage
   *
   *   debugLogTime("ContentContainer render", <any data>, "ContentContainer.render");
   *
   *  Expected output
   *
   *   ContentContainer render : 11:27:11:483 {source: undefined, companyId: "1", userId: 2, action: null, audit: null, …}
   */
  const logginEnabled = localStorage.getItem("debugLogTime");
  if (logginEnabled) {
    try {
      const enabledFor = JSON.parse(logginEnabled);
      if (enabledFor.find((element) => enabledModule.indexOf(element) !== -1)) {
        let current = new Date();
        if (object) {
          console.log(
            `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}:${current.getMilliseconds()} ${enabledModule}: ${msg} `,
            object
          );
        } else {
          console.log(
            `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}:${current.getMilliseconds()} ${enabledModule}: ${msg}`
          );
        }
      }
      if (trace) console.trace();
    } catch (error) {
      console.error(error);
    }
  }
};

export const covertObjectToArray = (obj) => {
  console.log(Object.entries(obj));
};

export const debugLogGroup = (groupName, enabledModule, enabled) => {
  const logginEnabled = localStorage.getItem("debugLogTime");
  if (logginEnabled) {
    try {
      const enabledFor = JSON.parse(logginEnabled);
      if (enabledFor.find((element) => enabledModule.indexOf(element) !== -1)) {
        if (enabled) {
          console.group(groupName);
        } else {
          console.groupEnd();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * Map data to C3 chart library data types
 *
 * @param {*} data
 * @param {*} table
 * @param {*} fields
 */
export const convertToC3Data = (data, table, fields) => {};

/**
 *
 * Replace all strings in a string ( a template ) where the string(s) to be replaced
 *   are surrounded by curly braces, e.g. "Some sentence {replaceMe} and {something} else...".
 *   There could be multiple occurances of the same value. All occurances should be replaced.
 *   Goal is to have all tests pass !
 *
 * Example:
 *   let template = "The quick {color} {animal} jumps over the {description} dog";
 *   let replacements = {
 *     color: "brown",
 *     animal: "fox",
 *     description: "crazy"
 *   };
 *   const result = fornat(template,replacements);
 *   expected result => "The quick brown fox jumps over the crazy dog"
 *
 * Note: 1. multiple ocurrances should all be replaced
 *           2. template values not specified in values should be ignored
 *           3. template values specified but not in template should be ignored
 *
 * Pre conditions/assumptions:
 *   sentence - has to be a valid string, empty string is ok.
 *   values - has to be an object, cannot be an array or null
 *
 * @param {sting} template - the template string containing zero or more replacements
 * @param {object} replacements - an object containing the replacement mappings
 *
 * @returns a string with all values being replaced if they were
 *        specified in values
 */
export const formatTemplate = (template, replacements) => {
  const keys = Object.keys(replacements);
  keys.forEach((key) => {
    template = template.replace(new RegExp(`{${key}}`, "g"), replacements[key]);
  });
  return template;
};

const findGroup = (groups, parent) => {
  if (!parent.children) {
    return null;
  }

  if (!groups || groups.length === 0) {
    return null;
  }

  // get the first group
  const group = groups[0];

  // strip the first group
  groups.splice(0, 1);

  const found = parent.children.find((g, index) => {
    return g.name === group;
  });

  if (!found) {
    return null;
  }

  // no more groups left
  if (found && groups.length === 0) {
    return found;
  }

  found.group = groups.join(":");

  // dig deeper recursively
  return findGroup(groups, found);
};

const createGroup = (groups, root, report) => {
  let newGroup = null;

  groups.forEach((group, index) => {
    const found = root.children.find((g) => {
      return g.name === group;
    });

    if (!found) {
      if (!root.children) {
        root.children = [];
      }
      let groupsForGroup = groups.map((x) => x);
      if (index < groups.length - 1) {
        groupsForGroup.splice(1 + index, groups.length - 1);
      }
      newGroup = {
        id: `${group}-${report.group}`,
        name: group,
        group: groupsForGroup.join(":"),
        ...(index < groups.length - 1 && { children: [] }),
      };

      root.children.push(newGroup);
      // group at this level was NOT found set newGroup as root
      // this causes the next iteration to go deeper
      root = newGroup;
    } else {
      // group at this level was found set as root
      // this causes the next iteration to go deeper
      root = found;
    }
  });
  return newGroup;
};

const buildIdPath = (parent, parentPath, expandAll) => {
  // clone path
  const path = parentPath.map((x) => x);
  path.unshift(parent.id);
  parent.path = path;
  if (parent.children) {
    expandAll.push(parent.id);
    parent.children.forEach((child) => {
      buildIdPath(child, path, expandAll);
    });
  }
};

export const convertItemsToTreeData = (items, rootName) => {
  let root = {
    id: "root",
    name: rootName,
    isLeaf: false,
    isRoot: true,
    isCommon: false,
    children: [],
    expandAll: [],
  };

  const ids = Object.keys(items);

  // for each item id
  ids.forEach((id) => {
    const item = items[id];
    const groups = item.group.split(":");
    let group;
    if (groups.length === 1 && groups[0].length === 0) {
      group = root;
    } else {
      group = findGroup(
        groups.map((x) => x),
        root
      );
    }

    if (!group) {
      // recursively create all missing groups
      group = createGroup(groups, root, item);
      debugLogTime("createGroup for", item, "common");
    }

    if (!group.children) {
      group.children = [];
    }

    group.children.push({
      id: item.id,
      name: item.name,
      isLeaf: true,
      isCommon: item.isCommon,
      group: item.group,
      data: item,
    });
    debugLogTime(`${group.name}`, group, "common");
  });

  // builds out navigation path
  buildIdPath(root, [], root.expandAll);

  return root;
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
};

/**
 * ONLY TO BE USED IN mapStateToProps()
 *
 * There are 2 ways to get the report depending on use case.
 *   1. reportId is passed in and report is retrieved from redux store
 *   2. the report object is passed in and used
 *
 * The 2nd case is for making changes to the report without wanting to save it in redux store
 * i.e. in a report editor to immediately make the changes visible
 *
 * @param {*} state
 * @param {*} ownProps
 */
export const getReport = (state, ownProps) => {
  // Always use the report stored in the state, not the one passed in
  // The are issues where some 3rd party components add circular data to the report which causes
  // problems when saving the report
  if (ownProps.report) {
    return state.userData.reports[ownProps.report.id] || ownProps?.report;
  }

  return state.userData.reports[ownProps.reportId];
};

/**
 * ONLY TO BE USED IN mapStateToProps()
 *
 * There are 2 ways to get the dashboard depending on use case.
 *   1. dashboardId is passed in and dashboard is retrieved from redux store
 *   2. the dashboard object is passed in and used
 *
 * The 2nd case is for making changes to the dashboard without wanting to save it in redux store
 * i.e. in a dashboard editor to immediately make the changes visible
 *
 * @param {*} state
 * @param {*} ownProps
 */
export const getDashboard = (state, ownProps) => {
  // Always use the dashboard stored in the state, not the one passed in
  // The are issues where some 3rd party components add circular data to the dashboard which causes
  // problems when saving the dashboard
  if (ownProps.dashboard) {
    return state.userData.dashboards[ownProps.dashboard.id];
  }

  return state.userData.dashboards[ownProps.dashboardId];
};

// memoized retrieved values...
const getDashboardMap = (state) => state.userData.dashboards;

export const getDashboards = createSelector([getDashboardMap], (dashboards) => {
  return dashboards;
});

const getReportsMap = (state) => state.userData.reports;

export const getReports = createSelector([getReportsMap], (reports) => {
  return reports;
});

export const featureEnabled = (name, featureFlags, groups, userId) => {
  const env = process.env.REACT_APP_STAGE === "production" ? "production" : "development";
  let found = false;
  if (!featureFlags) return false;
  try {
    if (featureFlags[env][name].generalAccess) {
      return true;
    }
    if (featureFlags[env][name].userIds.indexOf(userId) !== -1) {
      return true;
    }
    groups.forEach((group) => {
      if (
        featureFlags[env][name].userGroups.find((ug) => {
          // eslint-disable-next-line
          return ug == group;
        })
      ) {
        found = true;
      }
    });
  } catch (error) {
    console.error(error);
  }
  return found;
};
