import merge from "deepmerge";
import { API } from "../api/api";
import Config from "../config";

import { UPDATE_APP_BUG_REPORTS } from "../actions/bugActions";
import { UPDATE_BUG_REPORT } from "../actions/bugActions";

export default function bugReports(
  state = {
    loaded: false,
    reports: [],
  },
  action
) {
  switch (action.type) {
    case UPDATE_APP_BUG_REPORTS: {
      const newState = merge(state, action.reports);
      newState.loaded = true;
      return newState;
    }
    case UPDATE_BUG_REPORT: {
      state.reports[action.bugreport.id] = action.bugreport;
      API.post(`/addBugReport?bucketName=report-zone-data`, Config.baseURLCommonService, action.bugreport);
      return state;
    }
    default:
      return state;
  }
}
