export const LicenseType = {
  FREE: 0,
  TRIAL: 1,
  MONTHLY: 3,
  ANNUAL: 4,
  HIDDEN: 5,
  BETA: 6,
  MENTAL: 7,
};

export const DataType = {
  CLIO: 0,
  PANTHER: 1,
};

export const VisualizationType = {
  NUMBER: "number",
  TABLE: "table",
  CHART: "chart",
  HTML: "html",
};

export const ChartType = {
  BAR_CHART: "barChart",
  LINE_CHART: "lineChart",
  AREA_CHART: "areaChart",
  COMPOSED_CHART: "composedChart",
  SCATTER_CHART: "scatterChart",
  PIE_CHART: "pieChart",
  RADAR_CHART: "radarChart",
  RADIAL_RADAR_CHART: "radialRadarChart",
  TREE_MAP: "treeMap",
  WORD_MAP: "wordMap",
};

export const NumberType = {
  STYLED_NUMBER: "styledNumber",
  PERCENT_NUMBER: "percentNumber",
  ICON_NUMBER: "iconNumber",
  RANGE_NUMBER: "rangeNumber",
  GAUGE_NUMBER: "gaugeNumber",
};

export const LoadingState = {
  INIT: "initialize",
  AUTH: "authenticate",
  DATA: "data",
  DONE: "completed",
  LICENSE: "license",
};

export const NodeType = {
  REPORTS_AND_DASHBOARDS: "reportsAndDashboards",
  REPORTS: "reports",
  DASHBOARDS: "dashboards",
};

export const SortOrder = {
  ASC: "asc",
  DESC: "desc",
};

export const ONE_HOUR = 1000 * 60 * 60;
export const FOUR_HOURS = 1000 * 60 * 60 * 4;
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const ONE_MONTH = 1000 * 60 * 60 * 24 * 31;
export const CLIO_AUTH = "clio";
export const DEFAULT_DB_NAME = "ReportZone";
export const MILLISECS_PER_YEAR = 31536000000;
