import { combineReducers } from "redux";
import cognito from "./cognito";
import userData from "./userData";
import appState from "./appState";
import loadingState from "./loadingState";
import appApi from "./appApi";
import selection from "./selection";
import bugReports from "./bugReports";
import features from "./features";

/**
 * Home made for no good reason...
 *
 * @param {*} reducers
 */
// function combineReducers(reducers) {
//   // First get an array with all the keys of the reducers (the reducer names)
//   const reducerKeys = Object.keys(reducers);

//   return function combination(state = {}, action) {
//     // This is the object we are going to return.
//     const nextState = {};

//     // Loop through all the reducer keys
//     for (let i = 0; i < reducerKeys.length; i++) {
//       // Get the current key name
//       const key = reducerKeys[i];
//       // Get the current reducer
//       const reducer = reducers[key];
//       // Get the the previous state
//       const previousStateForKey = state[key];
//       // Get the next state by running the reducer
//       const nextStateForKey = reducer(previousStateForKey, action);
//       // Update the new state for the current reducer
//       nextState[key] = nextStateForKey;
//     }
//     return nextState;
//   };
// }

const appReducer = combineReducers({
  cognito,
  userData,
  appState,
  selection,
  loadingState,
  appApi,
  bugReports,
  features,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
