import { debugLogTime } from "../utils/common";

export const ADD_PROFILE = "profiles.ADD_PROFILE";
export const UPDATE_PROFILE = "profiles.UPDATE_PROFILE";
export const DELETE_PROFILE = "profiles.DELETE_PROFILE";
export const UPDATE_PROFILE_LIST = "profiles.UPDATE_PROFILE_LIST";

export function updateProfileList(profiles, isAdmin, isFirmAdmin) {
  debugLogTime("updateProfileList", profiles, "actions");
  return {
    type: UPDATE_PROFILE_LIST,
    profiles,
    isAdmin,
    isFirmAdmin,
  };
}

export function addProfile(profile, isAdmin, isFirmAdmin) {
  debugLogTime("addProfile", profile, "actions");
  return {
    type: ADD_PROFILE,
    profile,
    isAdmin,
    isFirmAdmin,
  };
}

export function updateProfile(profile, isAdmin, isFirmAdmin) {
  debugLogTime("updateProfile", profile, "actions");
  return {
    type: UPDATE_PROFILE,
    profile,
    isAdmin,
    isFirmAdmin,
  };
}

export function deleteProfile(profile, isAdmin, isFirmAdmin) {
  debugLogTime("deleteProfile", profile, "actions");
  return {
    type: DELETE_PROFILE,
    profile,
    isAdmin,
    isFirmAdmin,
  };
}
