import React from "react";

import { SignIn } from "aws-amplify-react";
import styled from "styled-components";

import {
  Card,
  CardContent,
  Button,
  ButtonBase,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import customTheme from "./themes/customTheme";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./CustomSignIn.styles";

const SSO = styled.div`
  button,
  a.button {
    // Updated CSS for SSO button guidelines

    display: flex;
    padding: 0 12px;
    position: relative;
    height: 32px;
    background-color: #0070e0;
    background-image: linear-gradient(-180deg, #0070e0 0%, #0f478d 100%);
    border: 1px solid #0f478d;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    align-items: center;
    //justify-content: space-around;

    &:hover,
    &:focus {
      background-image: linear-gradient(-180deg, #0f478d 2%, #0f478d 100%);
    }

    &:hover:before {
      content: "";
      border-bottom: 2px solid #a3dcff;
      position: absolute;
      bottom: 1px;
      left: 1px;
      right: 1px;
      border-radius: 0 0 1px 1px;
    }

    &:focus:after {
      box-shadow: 0 0 0 2px #0070e0;
      outline: 1px solid transparent;
      content: "";
      border-radius: 4px;
      border: 1px solid #fff;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
    }

    // White button theme from the styleguide

    &.white {
      color: #011c37;
      background-color: #f5f7f8;
      background-image: linear-gradient(-180deg, #f6f8f9 0%, #dfe6e9 100%);
      border-color: #b0bec5;

      &:hover,
      &:focus {
        background-image: linear-gradient(-180deg, #dfe6e9 2%, #dfe6e9 100%);
      }

      &:hover:before {
        border-color: #011c37;
      }

      &:focus:after {
        box-shadow: 0 0 0 2px #011c37;
      }
    }

    // Black button theme from the styleguide

    &.black {
      color: #f5f7f8;
      background-color: #011c37;
      background-image: linear-gradient(-180deg, #202020 0%, #090909 100%);
      border-color: #090909;

      &:hover,
      &:focus {
        background-image: linear-gradient(-180deg, #090909 2%, #090909 100%);
      }

      &:hover:before {
        border-color: #f5f7f8;
      }

      &:focus:after {
        box-shadow: 0 0 0 2px #090909;
      }
    }

    //  Custom styles, overrides colours with Switchboard theme

    width: -moz-available;
    width: -webkit-fill-available;
    justify-content: center;

    color: #ffffff;
    background-color: #10b9df;
    background-image: linear-gradient(-180deg, #10b9df 0%, #0c91af 100%);
    border: 1px solid #0c91af;

    &:hover,
    &:focus {
      background-image: linear-gradient(-180deg, #0c91af 2%, #0c91af 100%);
    }

    &:hover:before {
      border-bottom: 2px solid #ffffff;
    }

    &:focus:after {
      box-shadow: 0 0 0 2px #004670;
    }
  }

  // For links that need to look like buttons

  a.button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-pack: distribute;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    -webkit-box-sizing: border-box;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
  }

  // To position the Clio logo in the button/link

  button img,
  a.button img {
    padding-right: 5px;
    height: 20px;
    width: 20px;
  }
`;

const theme = createTheme(customTheme);

class MySignIn extends SignIn {
  state = {
    showPassword: false,
  };

  forgotPasswordClick = () => this.changeState("forgotPassword");
  createAccountClick = () => this.changeState("signUp");
  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.signIn();
    }
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  showComponent() {
    const { showPassword } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Card className={classes.card} raised={false} onKeyPress={this.handleKeyDown}>
            <CardContent className={classes.cardContent}>
              <span className={classes.logo}>
                <img className={classes.logoImage} src="/img/puzzle-200px.svg" alt="company-logo"></img>
                <span className={classes.logoText}>
                  <span className={classes.logoFont} style={{ color: "#5e6fa3" }}>
                    Report
                  </span>
                  <span className={classes.logoFont} style={{ color: "#93dbe9" }}>
                    Zone
                  </span>
                </span>
              </span>
              <Typography variant="h5" align="center" paragraph>
                Sign in
              </Typography>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input
                  className={classes.textfield}
                  autoFocus
                  id="username"
                  label="Username"
                  inputProps={{
                    key: "username",
                    name: "username",
                  }}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  className={classes.textfield}
                  id="password"
                  inputProps={{
                    key: "password",
                    name: "password",
                  }}
                  onChange={this.handleInputChange}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <FormHelperText className={classes.textHelper}>
                  Forgot your password ?
                  <ButtonBase className={classes.buttonLink} onClick={this.forgotPasswordClick}>
                    Reset Password
                  </ButtonBase>
                </FormHelperText>
                <FormHelperText className={classes.textHelper}>
                  No Account ?
                  <ButtonBase className={classes.buttonLink} onClick={this.createAccountClick}>
                    Create Account
                  </ButtonBase>
                </FormHelperText>
              </FormControl>
              <Button className={classes.button} type="submit" variant="contained" onClick={this.signIn}>
                Sign In
              </Button>
              {false ? (
                <SSO className={classes.ssoButton}>
                  <a
                    class="button"
                    tabindex="0"
                    href="https://account.clio.com/oauth2/auth?response_type=code&client_id=1149587a-4510-4798-b525-0dc4fe26953d&scope=openid&state=12345678&redirect_uri=https://report-zone.auth.us-east-1.amazoncognito.com/oauth2/idpresponse"
                  >
                    Sign in with Clio
                  </a>
                </SSO>
              ) : null}
            </CardContent>
          </Card>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, { theme: true })(MySignIn);
