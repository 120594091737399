import { SET_SELECTED_REPORT, SET_SELECTED_DASHBOARD } from "../actions/selectedActions";

export default function selection(state = { report: null, dashboard: null }, action) {
  switch (action.type) {
    case SET_SELECTED_REPORT: {
      const newState = { report: action.report };
      return { ...state, ...newState };
    }
    case SET_SELECTED_DASHBOARD: {
      const newState = { dashboard: action.dashboard };
      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
