import merge from "deepmerge";
import { UPDATE_APP_STATE } from "../actions/appStateActions";

import { LoadingState } from "../constants";
export default function appState(
  state = {
    isLoading: true,
    loadingMessage: "",
    loadingState: LoadingState.INIT,
  },
  action
) {
  switch (action.type) {
    case UPDATE_APP_STATE: {
      const newState = merge(state, action.appState);
      // TODO: update show??Page attributes
      return { ...state, ...newState };
    }
    default:
      return state;
  }
}
