
    /* eslint-disable */
    /* tslint:disable */
    
    // generated by react-imported-component, DO NOT EDIT     
    import {assignImportedComponents} from 'react-imported-component/macro';
    undefined    
    
    // all your imports are defined here
    // all, even the ones you tried to hide in comments (that's the cost of making a very fast parser)
    // to remove any import from here
    // 1) use magic comment `import(/* client-side */ './myFile')` - and it will disappear
    // 2) use file filter to ignore specific locations (refer to the README - https://github.com/theKashey/react-imported-component/#server-side-auto-import)
    // 3) use .imported.js to control this table generation (refer to the README - https://github.com/theKashey/react-imported-component/#-imported-js)
    
    const applicationImports = assignImportedComponents([
      [() => import('./application/account/AccountApp'), '', './src/application/account/AccountApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/account/license/LicensePage'), '', './src/application/account/license/LicensePage', false] /* from ./src/main/Routes.js */,
      [() => import('./application/admin/AdminApp'), '', './src/application/admin/AdminApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/admin/FirmAdminApp'), '', './src/application/admin/FirmAdminApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/bugReport/BugReportApp'), '', './src/application/bugReport/BugReportApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/conflictCheck/ConflictCheckApp'), '', './src/application/conflictCheck/ConflictCheckApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/contact/ContactApp'), '', './src/application/contact/ContactApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/dashboard/DashboardApp'), '', './src/application/dashboard/DashboardApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/dashboard/DashboardList'), '', './src/application/dashboard/DashboardList', false] /* from ./src/application/dashboard/DashboardApp.jsx */,
      [() => import('./application/dashboard/components/add/AddDashboard'), '', './src/application/dashboard/components/add/AddDashboard', false] /* from ./src/main/Routes.js */,
      [() => import('./application/dashboard/components/edit/EditDashboard'), '', './src/application/dashboard/components/edit/EditDashboard', false] /* from ./src/main/Routes.js */,
      [() => import('./application/features/FeaturesApp'), '', './src/application/features/FeaturesApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/features/WishlistApp'), '', './src/application/features/WishlistApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/home/HomeApp'), '', './src/application/home/HomeApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/import/ImportApp'), '', './src/application/import/ImportApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/news/NewsApp'), '', './src/application/news/NewsApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/notifications/NotificationApp'), '', './src/application/notifications/NotificationApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/preferences/PreferencesApp'), '', './src/application/preferences/PreferencesApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/reporting/ReportingApp'), '', './src/application/reporting/ReportingApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/reporting/components/add/AddReport'), '', './src/application/reporting/components/add/AddReport', false] /* from ./src/main/Routes.js */,
      [() => import('./application/reporting/components/charts/ChartRenderer'), '', './src/application/reporting/components/charts/ChartRenderer', false] /* from ./src/application/reporting/components/ReportRenderer.jsx */,
      [() => import('./application/reporting/components/charts/HTMLRenderer'), '', './src/application/reporting/components/charts/HTMLRenderer', false] /* from ./src/application/reporting/components/ReportRenderer.jsx */,
      [() => import('./application/reporting/components/charts/NumberRenderer'), '', './src/application/reporting/components/charts/NumberRenderer', false] /* from ./src/application/reporting/components/ReportRenderer.jsx */,
      [() => import('./application/reporting/components/edit/EditReport'), '', './src/application/reporting/components/edit/EditReport', false] /* from ./src/main/Routes.js */,
      [() => import('./application/reporting/components/sources/ComplexDataSourceEditor'), '', './src/application/reporting/components/sources/ComplexDataSourceEditor', false] /* from ./src/application/reporting/components/edit/settings/DataSettings.jsx */,
      [() => import('./application/reporting/components/sources/DataSourceEditor'), '', './src/application/reporting/components/sources/DataSourceEditor', false] /* from ./src/application/reporting/components/edit/settings/DataSettings.jsx */,
      [() => import('./application/reporting/components/tables/TableRenderer'), '', './src/application/reporting/components/tables/TableRenderer', false] /* from ./src/application/reporting/components/ReportRenderer.jsx */,
      [() => import('./application/repository/RepositoryApp'), '', './src/application/repository/RepositoryApp', false] /* from ./src/main/Routes.js */,
      [() => import('./application/sandbox/Sandbox'), '', './src/application/sandbox/Sandbox', false] /* from ./src/main/Routes.js */,
      [() => import('./application/visualization/VisualizationApp'), '', './src/application/visualization/VisualizationApp', false] /* from ./src/main/Routes.js */,
      [() => import('./components/json/JsonEditor'), '', './src/components/json/JsonEditor', false] /* from ./src/application/dashboard/components/view/DashboardData.jsx */,
      [() => import('./main/DataLoader'), '', './src/main/DataLoader', false] /* from ./src/main/Routes.js */,
      [() => import('./main/MainContainer'), '', './src/main/MainContainer', false] /* from ./src/App.jsx */,
      [() => import('jodit-react'), '', 'jodit-react', false] /* from ./src/application/reporting/components/edit/settings/HTMLSettings.jsx */,
    ]);
    
    export default applicationImports;
    
    // @ts-ignore
    if (module.hot) {
       // these imports would make this module a parent for the imported modules.
       // but this is just a helper - so ignore(and accept!) all updates
       
       // @ts-ignore
       module.hot.accept(() => null);
    }    
    