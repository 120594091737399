import merge from "deepmerge";
import { UPDATE_LOADING_STATE } from "../actions/loadingActions";

export default function loadingState(state = {}, action) {
  switch (action.type) {
    case UPDATE_LOADING_STATE: {
      const newState = merge(state, action.loadingState);
      return { ...state, ...newState };
    }
    default:
      return state;
  }
}
