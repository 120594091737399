import { debugLogTime } from "../utils/common";
export const UPDATE_FEATURE_REQUESTS = "featureRequestList.UPDATE";
export const UPDATE_FEATURE_REQUEST = "featureRequest.UPDATE";
export const UPDATE_FEATURE_LIST = "featureList.UPDATE";
export const UPDATE_FEATURE = "feature.UPDATE";

export function updateFeatureRequests(requests) {
  debugLogTime("updateFeatureRequests", requests, "actions");
  return {
    type: UPDATE_FEATURE_REQUESTS,
    requests,
  };
}

export function updateFeatureRequest(request) {
  debugLogTime("updateFeatureRequest", request, "actions");
  return {
    type: UPDATE_FEATURE_REQUEST,
    request,
  };
}

export function updateFeaturelist(featurelist) {
  debugLogTime("updateFeaturelist", featurelist, "actions");
  return {
    type: UPDATE_FEATURE_LIST,
    featurelist,
  };
}

export function updateFeature(feature) {
  debugLogTime("updateFeature", feature, "actions");
  return {
    type: UPDATE_FEATURE,
    feature,
  };
}
