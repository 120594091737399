import { debugLogTime } from "../utils/common";
export const UPDATE_APP_BUG_REPORTS = "bugReports.UPDATE";
export const UPDATE_BUG_REPORT = "bugReport.UPDATE";

export function updateBugReports(reports) {
  debugLogTime("updateBugReports", reports, "actions");
  return {
    type: UPDATE_APP_BUG_REPORTS,
    reports,
  };
}
export function updateBugReport(bugreport) {
  debugLogTime("updateBugReport", bugreport, "actions");
  return {
    type: UPDATE_BUG_REPORT,
    bugreport,
  };
}
